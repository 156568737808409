
export default `
  colorCombination {
    ...on colorCombination_BlockType {
      backgroundColor
      textColor
      accentColor
    }
  }
`
