import { idtype, upload } from "querypieces"
import colorCombination from "blocks/color-combination/query"

export default `
assetBuilder {
  ...on assetBuilder_graphic_BlockType {
    ${idtype}
    svg: upload {
      ${upload}
    }
  }
  ...on assetBuilder_colorCombination_BlockType {
    ${idtype}
    colors: children {
      ...on assetBuilder_combination_BlockType {
        ${idtype}
        ${colorCombination}
      }
    }
  }
}
`
