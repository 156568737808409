import { idtype, upload } from "querypieces"

export default `{
  navTiles: entries(section: "page", type: "page", id: ["not", 4]) {
    ...on page_page_Entry {
      ${idtype}
      title
      slug
      image: upload {
        ${upload}
      }
    }
  }
  page: entries(section: "page", slug: "home") {
    ...on page_page_Entry {
      ${idtype}
      pageTitle
      introductionCopy
    }
  }
}
`
