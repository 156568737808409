import React from "react"
import "./tiles.scss"

import ContentBlocks from "components/content-blocks";

const Tiles = ({tiles}) => {
  return <ul className="tile-grid">
    <ContentBlocks entry={{
      contentBlocks: tiles
    }} />
  </ul>
}

export default Tiles
