import { idtype } from "querypieces"
import gridItem from "blocks/grid-item/query"

export default `
...on contentBlocks_gridLinks_BlockType {
  ${idtype}
  items: children {
    ${gridItem}
  }
}
`
