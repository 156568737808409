import React from "react"
import { Link as RouterLink } from "react-router-dom"
import Image from "blocks/image"
import Link from "blocks/link"
import "./grid-item.scss"

const GridItem = ({uri, title, heading, image, passwordRequired, link}) => {

  if (link) {
    let linkField = link.filter(li => li.typeHandle === "link")

    return <Link {...linkField[0].linkField} className="grid-item">
      {link.map(block => {
        if (block.typeHandle === "image") {
          return block.upload && block.upload[0] ? <div
            className="grid-item__hover"
            key={block.typeHandle}>
            <Image image={block.upload[0]} />
            <span>{linkField[0].linkField.text}</span>
          </div> : null
        }
        if (block.typeHandle === "heading") {
          return <p key={block.id}>{block.heading}</p>
        }
        return null
      })}
    </Link>
  } else {
    return <RouterLink to={`/${uri}`} className="grid-item">
      <div className="grid-item__hover">
        {image && image[0] ? <Image image={image[0]} /> : null}
        <span>customize</span>
      </div>
      <p>{heading ? heading : title}</p>
      {//passwordRequired ? <span>Password required</span> : null
      }
    </RouterLink>
  }
}

export default GridItem
