import React from 'react';
import {
  Switch,
  Route
} from "react-router-dom"
import ScrollMemory from "react-router-scroll-memory"
import Header from "components/header"
import Footer from "components/footer"
import Home from "components/home"
import Page from "components/page"

import "scss/main.scss";

function App() {
  return (
    <main>
      <ScrollMemory />
      <Header />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path={"/:parent/:slug"} component={Page} />
        <Route path={"/:slug?"} component={Page} />
      </Switch>
      <Footer />
    </main>
  );
}

export default App;
