import React from "react";
import SVG from "react-inlinesvg"
import arrow from "svgs/arrow.svg"
import { downloadEvent } from "helpers"

const DownloadGraphic = ({downloadUrl,title}) => {
  return downloadUrl ? <a
    className="graphic-download"
    href={downloadUrl}
    download={"PowerThePolls"}
    onClick={()=>{ downloadEvent(`download PowerThePolls.jpg`) }}
  >
    <span>
      Download
      <SVG src={arrow} alt="arrow icon" />
    </span>
  </a> : null
}

export default DownloadGraphic
