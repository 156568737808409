import React from "react"
import ContentBlocks from "components/content-blocks";
import "./rows.scss"

const Rows = ({rows}) => {
  return <div className="rows">
    <ContentBlocks entry={{
      contentBlocks: rows
    }} />
  </div>
}

export default Rows
