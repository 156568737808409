import React, { useEffect, useState } from "react";
// import SVG from "react-inlinesvg"
import DownloadGraphic from "./download-graphic"
// import arrow from "svgs/arrow.svg"
import { styleMarkup, slugify, svgToImage } from "helpers"
import "./graphic.scss"

const Graphic = ({svg, colors, size}) => {
  const [svgText,setSvgText] = useState()
  const [downloadUrl,setDownloadUrl] = useState()
  const {backgroundColor, textColor, accentColor} = colors ? colors : {}

  console.log(svg)
  let id = slugify(svg.title)

  // TODO remove...
  let url = svg.url.replace("localhost:3000", "toolkit.powerthepolls.org")

  useEffect(() => {
    fetch(url)
      .then(r=>r.text())
      .then(text => {
        setSvgText(text);
      })
  },[url])

  let markup = svgText ? styleMarkup(svgText,id,textColor,accentColor,backgroundColor) : null

  useEffect(() => {
    const img = new Image();
    img.src = "data:image/svg+xml;base64," + btoa(markup);
    img.onload = function() {
      svgToImage(markup,size).then((urls) => {
        setDownloadUrl({
          ...urls
        })
      })
    }

    img.onerror = function(e){
      // console.warn(e)
    }

  },[markup,size])

  return <div className="graphic">
    <div
      style={{
        backgroundColor: `${backgroundColor}`,
      }}
      dangerouslySetInnerHTML={{ __html: markup }}
    />
    <DownloadGraphic
      downloadUrl={downloadUrl ? downloadUrl.jpg : null}
      title={svg.title}
    />
  </div>
}

export default Graphic
