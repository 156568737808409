import { idtype, upload } from "querypieces"

export default `
...on contentBlocks_image_BlockType {
  ${idtype}
  upload {
    ${upload}
  }
}
`
