import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

export const downloadEvent = label => {
  if (window.gtag) {
    window.gtag('event', 'download', {
      'event_category': 'asset download',
      'event_label': label
    })
  } else {
    console.log('no gtag!')
  }
}

export const slugify = text =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')


export const splitMessage = message => message ? message.match(/(.*)\*(.+)\*(.*)/) : null;


export const hexToRGB = (h) => {
  let r = 0, g = 0, b = 0;

  r = "0x" + h[1] + h[2];
  g = "0x" + h[3] + h[4];
  b = "0x" + h[5] + h[6];

  return {
    rgb: `${+r}, ${+g}, ${+b}`,
    cmyk: rgb2cmyk(+r, +g, +b)
  }
}

const rgb2cmyk = (r, g, b, normalized) => {
  let c = 1 - (r / 255);
  let m = 1 - (g / 255);
  let y = 1 - (b / 255);
  let k = Math.min(c, Math.min(m, y));

  c = (c - k) / (1 - k);
  m = (m - k) / (1 - k);
  y = (y - k) / (1 - k);

  if(!normalized){
    c = Math.round(c * 10000) / 100;
    m = Math.round(m * 10000) / 100;
    y = Math.round(y * 10000) / 100;
    k = Math.round(k * 10000) / 100;
  }

  c = isNaN(c) ? 0 : c;
  m = isNaN(m) ? 0 : m;
  y = isNaN(y) ? 0 : y;
  k = isNaN(k) ? 0 : k;

  return `${c}, ${m}, ${y}, ${k}`
}

export const styleComponent = (Component,id="Download",color,secondaryColor,backgroundColor) => {
  id = id.replace(/[^a-zA-Z]/g,"")
  const StyledComponent = <Component id={id} xmlns="http://www.w3.org/2000/svg" />
  const staticMarkup = renderToStaticMarkup(StyledComponent);

  return styleMarkup(staticMarkup,id,color,secondaryColor,backgroundColor)
}

export const styleMarkup = (markup,id,color,secondaryColor,backgroundColor) => {
  id = id.replace(/[^a-zA-Z]/g,"")

  markup = markup
    .replace(/id="(?!secondary).*?"/,"")
    .replace(`<?xml version="1.0" encoding="UTF-8"?>`,"")
    .replace(`<?xml version="1.0" encoding="utf-8"?>`,"")
    .replace(/<!--[\s\S]*-->/,"")
    .trim()

  markup = [markup.slice(0,5),`id="${id}"`,markup.slice(5)].join(' ')
  markup = addBgRect(markup)

  const splitStaticMarkup = markup.split(/([\s\S]*?)><([\s\S]*)/);
  const styledMarkup = splitStaticMarkup[1] + `
    >
      ${styleTag(color,secondaryColor,backgroundColor,id)}
    <`.trim() + splitStaticMarkup[2];

  return styledMarkup
}

export const addBgRect = (existingMarkup) => {
  const [markup,viewBox] = removeBgRect(existingMarkup);
  if (!viewBox) {
    return existingMarkup
  } else {
    let width = +viewBox[3];
    let height = +viewBox[4];

    const splitMarkup = markup.split(/([\s\S]*?)>\s*?<([\s\S]*)/);
    const result = [
      splitMarkup[1],
      `><rect
        id="bg"
        x="${-viewBox[1]/2}"
        y="${-viewBox[2]/2}"
        width="${width}"
        height="${height}" /><`,
      splitMarkup[2]
    ].join('')
    return result
  }
}

export const removeBgRect = (markup) => {
  const viewBox = markup.match(/viewBox="([.\d]*) ([.\d]*) ([.\d]*) ([.\d]*)"/);
  if (!viewBox) {
    console.log(markup)
  } else {
    let width = +viewBox[3];
    let height = +viewBox[4];
    //remove existing rect
    const exp = `(<rect[^]*?width="${width}"[^]*?height="${height}"[^]*?)(></rect>|/>)`
    const re = new RegExp(exp,"g");
    markup = markup.replace(re,"")
  }

  return [markup,viewBox]
}

export const styleTag = (primaryColor,secondaryColor,backgroundColor,id="Download") => {
  id = id.replace(/[^a-zA-Z]/g,"")
  return `<style>
    svg#${id} {
      display: block;
      width: 100%;
      height: auto;
    }
    ${primaryColor ? `
      svg#${id} * {
        fill: ${primaryColor};
      }
    `: ''}
    svg#${id} #bg {
      fill: ${backgroundColor ? backgroundColor : '#670a85'};
    }
    ${secondaryColor ? `
      svg#${id} #secondary *,
      svg#${id} #secondary *,
      svg#${id} #Secondary *,
      svg#${id} #Secondary * {
        fill: ${secondaryColor};
      }
    `: ''}
  </style>`
}

export const isMobile = /iPhone|iPad|iPod|Android/i.test(typeof navigator !== "undefined" ? navigator.userAgent : null) || /iPhone|iPad|iPod|Android/i.test(typeof navigator !== "undefined" ? navigator.platform : null)

export const sizes = {
  "instagram": {
    size: [1080,1080]
  },
  // "facebook": {
  //   size: [1640,624]
  // },
  // "desktop": {
  //   size: [1920,1080]
  // },
  // "letter": {
  //   size: [612,792]
  // },
  // "mobile": {
  //   size: [1080,1920]
  // },
  "twitter": {
    size: [1500,500],
  }
}


export const svgToImage = (svg_xml,size) => {
  return new Promise((resolve,reject) => {
    const canvas = document.createElement("canvas")
    const context = canvas.getContext("2d")
    context.imageSmoothingQuality = "high"
    canvas.width = sizes[size].size[0]
    canvas.height = sizes[size].size[1]

    const img = new Image();
    img.src = "data:image/svg+xml;base64," + btoa(svg_xml);

    img.onload = function() {
      context.clearRect(0,0,canvas.width,canvas.height);
      context.drawImage(img, 0, 0, canvas.width, canvas.height);
      resolve({
        jpg: canvas.toDataURL('image/jpeg', 1.0)
      })
    }
  })
}
