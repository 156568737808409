import React from "react"
import Blocks from "blocks";

const ContentBlocks = ({ entry }) => {
  const { contentBlocks, ...rest } = entry;
  return (
    <Blocks blocks={contentBlocks} entry={rest} />
  );
};

export default ContentBlocks;
