// import lazySizes from "lazysizes";
import React from "react";
import { render, hydrate } from "react-dom";
import App from "components/app";
import { BrowserRouter } from "react-router-dom";
// import store from "redux/store.js";
// import { Provider as ReduxProvider } from "react-redux";

import { ApolloProvider } from "@apollo/react-hooks";
import createClient from "./createApolloClient";

const renderMethod = !!module.hot ? render : hydrate;
const client = createClient();

// lazySizes.init();

renderMethod(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// renderMethod(
//   <ReduxProvider store={store}>
//     <BrowserRouter>
//       <ApolloProvider client={client}>
//         <App />
//       </ApolloProvider>
//     </BrowserRouter>
//   </ReduxProvider>,
//   document.getElementById("root")
// );
