import { idtype, upload } from "querypieces"

export default `
  ...on contentBlocks_assetTile_BlockType {
    ${idtype}
    upload {
      ${upload}
    }
    title: pageTitle
    copy
    source
  }
`
