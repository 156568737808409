import {
  idtype,
  link
} from "querypieces"

export default `
...on contentBlocks_link_BlockType {
  ${idtype}
  linkField {
    ${link}
  }
}
`
