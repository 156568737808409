import React from "react"
import "./color-combination.scss"

const ColorCombination = ({colorCombination, setColors, current}) => {
  let color = colorCombination[0]
  let border = color.backgroundColor === "#ffffff" ? "1px solid var(--dark-gray)" : "none"
  let selected = current && color ? (current.backgroundColor === color.backgroundColor && current.textColor === color.textColor && current.accentColor === color.accentColor) : null

  return <div className={`color-selector${selected ? " selected" : ""}`}>
    <button
      onClick={()=>{
        setColors({
          backgroundColor: color.backgroundColor,
          textColor: color.textColor,
          accentColor: color.accentColor,
        })
      }}
      style={{
        backgroundColor: `${color.backgroundColor}`,
        color: `${color.textColor}`,
        border: border
      }}
    >A</button>
  </div>
}

export default ColorCombination
