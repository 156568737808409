import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";

export default (query, vars) => {
  const fetch = gql`
    query ${query}
  `;

  const { loading, error, data } = useQuery(fetch, {
    variables: vars,
    notifyOnNetworkStatusChange: true
  });

  return {
    loading,
    error,
    data
  };
};

