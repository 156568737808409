import { idtype, upload } from "querypieces"

export default `
  ...on contentBlocks_documentTile_BlockType {
    ${idtype}
    copy
    upload {
      ${upload}
    }
    file {
      ${upload}
    }
  }
`
