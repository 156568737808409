import { idtype } from "querypieces"
import heading from "blocks/heading/query"
import link from "blocks/link/query"
import image from "blocks/image/query"

export default `
...on contentBlocks_gridItem_BlockType {
  ${idtype}
  link: children {
    ${heading}
    ${link}
    ${image}
  }
}
`
