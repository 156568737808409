import React from "react"
import GridItem from "blocks/grid-item"
import "./grid-links.scss"

const GridLinks = ({items}) => {
  return <ul className={`grid-links ${items.length > 2 ? "grid-links--long" : ""}`}>
    {items.map(item => <li key={item.id}>
      <GridItem {...item} />
    </li>)}
  </ul>
}

export default GridLinks
