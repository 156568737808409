

export default `{
  entries(section: "page", type: "page", id: ["not", 4]) {
    id
    title
    slug
  }
}
`
