import React from "react"
import fetch from "fetch"
import { Link } from "react-router-dom"
import SVG from "react-inlinesvg"
// import { Helmet } from "react-helmet";
// import ReactHtmlParser from "react-html-parser";
import query from "./query"
import "./page.scss"

import Copy from "blocks/copy"
import GridLinks from "blocks/grid-links"
import ContentBlocks from "components/content-blocks";
import AssetBuilder from "blocks/asset-builder"
import arrow from "svgs/arrow.svg"

// const Seo = ({tags}) => {
//   return <Helmet>
//     {ReactHtmlParser(tags.metaTitleContainer)}
//     {ReactHtmlParser(tags.metaTagContainer)}
//   </Helmet>
// }

const Page = ({match}) => {
  const { loading, error, data } = fetch(query, { uri: match.url.replace("/","") });

  // console.log(error, data)

  if (loading) {
    return <PageLoading />;
  }
  if (error) {
    return <ErrorPage />;
  }

  if (data && data.entries.length < 1) {
    console.log("inside Not Found",data)
    return <NotFoundPage />;
  }

  if (loading === false && data === undefined && error === undefined) {
    return <ErrorPage />;
  }

  let page = data.entries[0]

  return <article className="max-width">
    <header className="page-intro">
      {page.parent ?
        <Link
          to={`/${page.parent.uri}`}
          title="go back to directory"
          className="back-link">
            <SVG src={arrow} alt="arrow icon" />
            <span>{`Back to ${page.parent.title}`}</span>
          </Link> : null
        }
      <h1>{page.pageTitle ? page.pageTitle : page.title}</h1>
      <Copy copy={page.introductionCopy} />
    </header>
    {page.typeHandle === "page" ?
      <div className="page">
        {page.children.length ? <GridLinks items={page.children} /> : null}
        <ContentBlocks entry={page} />
      </div>
      :
      <AssetBuilder
        assetBuilder={page.assetBuilder}
        customizable={page.customizable || page.customizeBlock}
        graphicType={page.typeHandle}
      />
    }
  </article>
}


export const ErrorPage = () => {
  return <section className="page page-error">
    <h1>500</h1>
    <p>Something went wrong. <Link className="inline-link" to="/" title="go home">Click here</Link> to go back to the home page.</p>
  </section>;
}

export const NotFoundPage = () => {
  return <section className="page page-error">
    <h1>404</h1>
    <p>The page you are looking for could not be found.</p>
    <p><Link className="inline-link" to="/" title="go home">Click here</Link> to go back to the home page.</p>
  </section>;
}

export const PageLoading = () => {
  return <section className="page page-load">
    <h2>Loading...</h2>
  </section>;
}

export default Page
