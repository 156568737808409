import React, { useState } from "react"
import { hexToRGB, downloadEvent } from "helpers"
import "./color-tile.scss"

const ColorTile = ({ name, color }) => {
  const [copyMessage, setCopyMessage] = useState("Copy Hex");

  let convertedColors = hexToRGB(color)

  return <li className="color-tile">
    <div className={`color-tile__block ${name === "Navy" ? " white" : ""}`}
      onClick={() => {
        navigator.clipboard.writeText(color)
        setCopyMessage("Hex Copied")
        downloadEvent(`copied hex value ${color}`)
      }}
      onMouseLeave={()=> {
        setTimeout(() => { setCopyMessage("Copy Hex") }, 300)
      }}
      style={{
        backgroundColor: color,
        width: `100%`,
        paddingBottom: `100%`
      }}
    >
      <span>{copyMessage}</span>
    </div>
    <h3>{name}</h3>
    <table className="color-tile__table">
      <tbody>
        <tr>
          <td className="color-tile__label">hex</td>
          <td className="color-tile__value">{color}</td>
        </tr>
        <tr>
          <td className="color-tile__label">rgb</td>
          <td className="color-tile__value">{convertedColors ? convertedColors.rgb : null}</td>
        </tr>
        <tr>
          <td className="color-tile__label">cmyk</td>
          <td className="color-tile__value">{convertedColors ? convertedColors.cmyk : null}</td>
        </tr>
      </tbody>
    </table>
  </li>
}

export default ColorTile
