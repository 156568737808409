import React from "react"
import "./copy.scss"

const Copy = ({copy}) => {
  return <div
    dangerouslySetInnerHTML={{ __html: copy }}
  />
}

export default Copy
