import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "fragmentTypes.json";
import fetch from "node-fetch";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const createClient = (params) => {
  const isClient = typeof window !== "undefined";
  const cache = new InMemoryCache({
    fragmentMatcher,
  })

  if (isClient) {
    cache.restore(window.__APOLLO_STATE__)
  }

  return new ApolloClient({
    link: new HttpLink({
      uri: isClient ?  `/api?pathname=${window.location.pathname}` : `${params && params.baseUrl ? params.baseUrl : "" }/api`,
      credentials: "same-origin",
      fetch: isClient && typeof window.fetch !== "undefined" ? window.fetch : fetch
    }),
    headers: {
      "X-Craft-Token": isClient && window.location.search.match(/\btoken=([^&]+)/)
        ? window.location.search.match(/\btoken=([^&]+)/)[1]
        : "",
    },
    cache,
    ssrForceFetchDelay: 100
  })
}

export default createClient
