import React from "react"
import "./document-tile.scss"

import Image from "blocks/image"

const DocumentTile = ({upload, file, copy}) => {
  let image = upload && upload[0] ? upload[0] : null
  let doc = file && file[0] ? file[0] : null

  return doc ? <li>
    <a href={doc.url}
      className="document-tile"
      download={doc.title}
      onClick={()=>{}}
    >
      <div className={"document-tile__link"}>
        <span>Download</span>
        <Image image={image} />
      </div>
    </a>
    <p className="document-tile__name">{copy}</p>
  </li> : null
}

export default DocumentTile
