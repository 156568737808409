import React from "react"
import { OutboundLink } from "react-ga";

import SVG from "react-inlinesvg"
import Facebook from "svgs/Facebook.svg"
import Instagram from "svgs/Instagram.svg"
import Twitter from "svgs/Twitter.svg"

import "./footer.scss"

const Footer = () => {
  return (
    <footer>
      <div className="max-width">
        <div className="footer-links">
          <div className="footer-col">
            <h2>
              <span>Get Updates on </span> <span>Power the Polls</span>
            </h2>
            <OutboundLink
              eventLabel="outboundClick"
              target="_blank"
              to="https://www.powerthepolls.org/">
              powerthepolls.com
            </OutboundLink>
          </div>
          <div className="footer-col">
            <div>Follow Us:</div>
            <div className="social-links">
              <OutboundLink
                eventLabel="outboundClick"
                target="_blank"
                to={`https://twitter.com/powerthepolls`}
              >
                <SVG src={Twitter} />
              </OutboundLink>
              <OutboundLink
                eventLabel="outboundClick"
                target="_blank"
                to={`https://www.instagram.com/powerthepolls/?hl=en`}
              >
                <SVG src={Instagram} />
              </OutboundLink>
              <OutboundLink
                eventLabel="outboundClick"
                target="_blank"
                to={`https://www.facebook.com/powerthepolls/`}
              >
                <SVG src={Facebook} />
              </OutboundLink>
            </div>
          </div>

          <div className="footer-col copyright">
            <OutboundLink
              eventLabel="outboundClick"
              target="_blank"
              to={`http://www.newventurefund.org/wp-content/uploads/New-Venture-Fund-Privacy-Policy.pdf`}
            >
              Privacy Policy
            </OutboundLink>
            Contact:
            <OutboundLink
              eventLabel="outboundClick" to="mailto:info@powerthepolls.org">
              info@powerthepolls.org
            </OutboundLink>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
