const [igw,igh] = [1000,1000]
const [tw,th] = [1200,700]

const pad = 40

export default {
  // INSTAGRAM
  instagram: {
    size: [igw,igh],
    // logo with string on above
    endorsement: {
      illustration: {
        size: 1.5,
        pos: [igw/5.5,igh/3.5]
      },
      text: {
        size: [igw - (pad*2),igh*0.175],
        pos: [pad,pad],
        xOffset: pad/2
      },
      upload: {
        pos: [0,0]
      }
    },
    // graphic with custom text on bottom
    copyBottom: {
      illustration: {
        size: 1,
        pos: [0,0]
      },
      text: {
        size: [igw-(pad*2),igh*0.25],
        xOffset: pad,
        yOffset: 0
      }
    },
    // graphic with custom text on top
    copyTop: {
      illustration: {
        size: 1,
        pos: [0,0]
      },
      text: {
        size: [igw*0.66,igh*0.75],
        xOffset: pad/2,
        yOffset: 0
      }
    },
    // logo with logo upload (side by side)
    default: {
      illustration: {
        size: 2,
        pos: [(igh/2) - pad,igh/4]
      },
      text: {
        size: [igw/2.2,igh/2.2],
        pos: [0,igh/4]
      },
      upload: {
        size: [igw/2,(igh/2) - (pad*2)],
        pos: [pad,igh/4]
      }
    },
    // uploaded image top, custom text bottom
    imageAndCopy: {
      illustration: {
        pos: [pad,igh*1.66]
      },
      text: {
        size: [igw-(pad*2), igh*0.33],
        pos: [pad,igh*1.33],
        xOffset: 0
      },
      upload: {
        size: [],
        pos: [pad,pad]
      }
    }
  },
  // TWITTER
  twitter: {
    size: [tw,th],
    // logo with string on above
    endorsement: {
      illustration: {
        size: 2,
        pos: [tw - th*.75, th*.25]
      },
      text: {
        size: [th-(pad*2), th-(pad*2)],
        pos: [pad,pad]
      },
      upload: {
        size: [th-(pad*2), th-(pad*2)],
        pos: [pad,pad]
      }
    },
    // graphic with custom text on bottom
    copyBottom: {
      illustration: {
        size: 1,
        pos: [(tw-igw),0]
      },
      text: {
        size: [igw-(pad*6),igh*0.25],
        height: igh*0.25,
        xOffset: 0,
        yOffset: -(pad/2)
      }
    },
    // graphic with custom text on top
    copyTop: {
      illustration: {
        size: 1,
        pos: [pad,0]
      },
      text: {
        size: [igw*0.66,igh*0.66],
        xOffset: pad/2,
        yOffset: 0
      }
    },
    // logo with logo upload (side by side)
    default: {
      illustration: {
        size: 2,
        pos: [tw - th*.75, th*.25]
      },
      text: {
        size: [th-(pad*2), th-(pad*2)],
        pos: [pad,pad]
      },
      upload: {
        size: [tw - th/2, th],
        pos: [tw - th*1.5, th*.25]
      }
    },
    // uploaded image top, custom text bottom
    imageAndCopy: {
      illustration: {
        pos: [pad,th*1.66]
      },
      text: {
        size: [(igw*0.8)-(pad*2),th*0.33],
        xOffset: -(pad/2)
      },
      upload: {
        size: [],
        pos: [pad,pad]
      }
    }
  },
}
