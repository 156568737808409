export const idtype = `
  id
  typeHandle
`

export const upload = `
  id
  url
  uri
  title
  mimeType
`

export const link = `
  url
  text
  type
  element {
    id
    uri
    title
    slug
  }
`;

export const legalCopy = `{
  helperText: globalSet(handle: "helperText") {
    ...on helperText_GlobalSet {
      helperText {
        imageUpload
        logoUpload
        customColor
      }
    }
  }
}`
