import React from "react"
import "./asset-tile.scss"

import { downloadEvent } from "helpers"
import Image from "blocks/image"

const AssetTile = ({
  upload,
  title,
  copy,
  source
}) => {
  let image = upload && upload[0] ? upload[0] : null
  return image ? <li>
    <a href={image.url.replace("localhost:3000", "192.241.129.105")}
      className={"asset-tile"}
      download={image.title}
      onClick={()=>{ downloadEvent(`download ${image.title}`) }}
    >
      <div className="asset-tile__hover">
        <span>Download</span>
        <Image image={image} />
      </div>
      <h3>{title}</h3>
      <p>{copy}</p>
      {source ? <p>
        <strong>Source: </strong>
        {source}
      </p> : null}
    </a>
  </li> : null
}

export default AssetTile
