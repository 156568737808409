import { idtype, upload } from "querypieces"

import assetTile from "blocks/asset-tile/query";
import colorTile from "blocks/color-tile/query";
import documentTile from "blocks/document-tile/query";
import copy from "blocks/copy/query";
import fonts from "blocks/fonts/query";
import gridItem from "blocks/grid-item/query";
import gridLinks from "blocks/grid-links/query";
import heading from "blocks/heading/query";
import image from "blocks/image/query";
import link from "blocks/link/query";
import tiles from "blocks/tiles/query";
import rows from  "blocks/rows/query";
import assetBuilder from "blocks/asset-builder/query";

const blocks = `
  ${assetTile}
  ${colorTile}
  ${documentTile}
  ${copy}
  ${fonts}
  ${gridItem}
  ${gridLinks}
  ${heading}
  ${image}
  ${link}
  ${tiles}
  ${rows}
`;

// seomatic {
//   metaTitleContainer
//   metaTagContainer
//   metaLinkContainer
//   metaScriptContainer
//   metaJsonLdContainer
// }
const seo = `
`

const customizer = `
  defaultCopy
  defaultImage {
    ${upload}
  }
  hasCustomColors
  alignment
  fontFamily
`

const assetPage = `
...on page_logoGraphic_Entry {
  ${idtype}
  title
  slug
  uri
  pageTitle
  introductionCopy
  image: upload {
    ${upload}
  }
  ${assetBuilder}
  customizable
  parent {
    title
    uri
  }
  ${seo}
}
...on page_customGraphic_Entry {
  ${idtype}
  title
  slug
  uri
  pageTitle
  introductionCopy
  image: upload {
    ${upload}
  }
  ${assetBuilder}
  customizeBlock {
    ...on customizeBlock_BlockType {
      ${customizer}
    }
  }
  parent {
    title
    uri
  }
  ${seo}
}
`

export default `query($uri: [String]){
  entries(section: "page", uri: $uri) {
    ${idtype}
    title
    ...on page_page_Entry {
      pageTitle
      introductionCopy
      contentBlocks {
        ${blocks}
      }
      children {
        ${assetPage}
      }
      ${seo}
    }
    ${assetPage}
  }
}
`
