import React from "react"
import "./fonts.scss"

const weight = copy => {
  switch (copy) {
    case "Black":
      return 900
    case "Bold":
      return 700
    case "Medium":
      return 500
    case "Regular":
      return 400
    case "Book":
      return 400
    case "Light":
      return 300
    case "Thin":
      return 100
    default:
      return 400
  }
}

const Fonts = ({fontFace}) => {
  return <ul className="fonts">
    {fontFace.map(font => {
      const { fontType, fontFamily, fontWeight, copy, size, lineHeight, caseStyle, id } = font

      return <li key={id} className="fonts-row">
        <h5 className="flex-center">{fontType}</h5>
        <p className="flex-center"
          style={{
            fontFamily: `${fontFamily}`,
            lineHeight: `${lineHeight}`,
            fontSize: `${size ? size.replace("x", "rem") : ""}`,
            fontWeight: `${weight(fontWeight)}`,
            textTransform: `${caseStyle ? caseStyle.toLowerCase() : ""}`
        }}>{copy}</p>

        <ul className="font__table">
          <li>
            <span className="font__label">family</span>
            <span className="font__value">{fontFamily}</span>
          </li>
          <li>
            <span className="font__label">size</span>
            <span className="font__value">{size}</span>
          </li>
          <li>
            <span className="font__label">weight</span>
            <span className="font__value">{fontWeight}</span>
          </li>
          <li>
            <span className="font__label">line-height</span>
            <span className="font__value">{lineHeight}</span>
          </li>
          <li>
            <span className="font__label">case</span>
            <span className="font__value">{caseStyle}</span>
          </li>
        </ul>
      </li>
    })}
  </ul>
}

export default Fonts
