import { idtype } from "querypieces"

export default `
...on contentBlocks_fonts_BlockType {
  ${idtype}
  fontFace {
    ...on fontFace_BlockType {
      id
      fontType
      fontFamily
      fontWeight
      copy
      size
      lineHeight
      caseStyle: case
    }
  }
}
`
