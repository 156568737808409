import React from "react"
import { Link } from "react-router-dom"
import Image from "blocks/image"
import Copy from "blocks/copy"
import SVG from "react-inlinesvg"
import logosvg from "svgs/logo.svg"
import fetch from "fetch";
import query from "./query"

import "./home.scss"

const Home = () => {
  const { data } =  fetch(query);

  let pageData = data ? data.page[0] : null

  return <article>
    <section className="home-hero">
      <SVG src={logosvg} alt={"Power the Polls logo"} />
      {pageData ? <header>
        <h1>{pageData.pageTitle}</h1>
        <Copy copy={pageData.introductionCopy} />
      </header> : null}
    </section>
    {data ? <NavGrid tiles={data.navTiles} /> : null}
  </article>
}

const NavGrid = ({tiles}) => {
  return tiles ? <ul className="nav-grid">
    {tiles.map(tile => <li key={tile.id}>
      <Link to={`/${tile.slug}`} className="nav-grid__tile">
        <span>{tile.title}</span>
        {tile.image[0] ? <Image image={tile.image[0]} /> : null}
      </Link>
    </li>)}
  </ul> :  null
}

export default Home
