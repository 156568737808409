import { idtype } from "querypieces"
import assetTile from "blocks/asset-tile/query"
import colorTile from "blocks/color-tile/query"
import documentTile from "blocks/document-tile/query"

export default `
...on contentBlocks_tiles_BlockType {
  ${idtype}
  tiles: children {
    ${assetTile}
    ${colorTile}
    ${documentTile}
  }
}
`
