import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import SVG from "react-inlinesvg"
import query from "./query"
import logosvg from "svgs/logo.svg"
import fetch from "fetch";
import "./header.scss"

const Header = () => {
  const [open,setOpen] = useState(false);
  const [logo,setLogo] = useState(false);

  let location = useLocation();
  let home = location.pathname === "/"

  useEffect(() => {
    setLogo(!home)
  }, [location, home]);

  return <nav className={open ? "nav-open" : ""}>
    <div className="header-links">
      {logo ? <Link to="/">
        <SVG src={logosvg} alt={"Vote Early Day logo"} />
      </Link> : <div/>}

      <button className="toggle-menu" onClick={() => {
        setOpen(open => !open)
      }}></button>
    </div>
    <Nav toggle={setOpen} />
  </nav>
}

const Nav = ({toggle}) => {
  const { data } =  fetch(query);

  return data ? <ul className="header-menu">
    {data.entries.map(page =>
      <li key={page.id}>
        <Link
          to={`/${page.slug}`}
          onClick={() => {
            toggle(open => !open)
          }}
        >{page.title}</Link>
      </li>
    )}
  </ul> : null
}

export default Header
