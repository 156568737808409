import React from "react";

// content blocks
import assetTile from "./asset-tile";
import colorTile from "./color-tile";
import documentTile from "./document-tile";
import copy from "./copy";
import fonts from "./fonts";
import gridItem from "./grid-item";
import gridLinks from "./grid-links";
import heading from "./heading";
import image from "./image";
import link from "./link";
import tiles from "./tiles";
import rows from "./rows";

const components = {
  assetTile,
  colorTile,
  documentTile,
  copy,
  fonts,
  gridItem,
  gridLinks,
  heading,
  image,
  link,
  tiles,
  rows
};

const Blocks = ({ blocks, entry }) => {
  return blocks.map((block) => {
    const Comp = components[block.typeHandle];
    if (!Comp) {
      console.warn(`Missing block type ${block.__typename}`);
      return null;
    }
    return <Comp key={block.id} {...block} entry={entry} />;
  });
};

export default Blocks;
