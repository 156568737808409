import { idtype } from "querypieces"
import fonts from "blocks/fonts/query"

export default `
...on contentBlocks_rows_BlockType {
  ${idtype}
  rows: children {
    ${fonts}
  }
}
`
