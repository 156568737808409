import React from "react"
import "./image.scss"

// TODO: src and proper alt tag
const Image = ({image}) => {
  let url = image.url.replace("localhost:3000", "toolkit.powerthepolls.org")
  return image ? <img src={url} alt={image.id} /> : null
}

export default Image
